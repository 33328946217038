@use '@angular/material' as mat;
@import 'material_variables';
@tailwind utilities;

// == MATERIAL CONFIG ====================================
$my-theme: mat.define-light-theme((
    color: (
        primary: mat.define-palette($material-primary),
        accent: mat.define-palette($material-accent)
    ),
    typography: $material-typography
));

@include mat.core;
@include mat.all-component-themes($my-theme);
// ========================================================

button {
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
}

// DEFAULT VARIABLES
// === COLORS ===
$primary-color: #103C50;
$secondary-color: #32DDFF;
$background-color: #F5F5F5;
$background-gradient-color: #F5F5F5;
$contrast-color: #333333;
// === FONTS ===
$primary-font-family: 'system-ui';
$secondary-font-family: 'system-ui';
/* HEADER */
.mat-toolbar.mat-primary {
  background: $background-color
}

button.mat-mdc-raised-button {
  width: 100%;
  text-transform: uppercase !important;
  padding: 25px 20px !important;
}

@media screen and (max-width: 420px) {
  .otp-input {
    width: 13.333% !important;
    margin-right: 0 !important;
    max-width: 40px !important;
    font-size: 24px !important;
    height: 40px !important;
  }
  .ng-otp-input-wrapper {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
  }
}
@import 'variables'; // IMPORTED VARIABLES

:root {
  --swiper-pagination-color: #{$primary-color};
}


/* BASIC */
html, body {
  height: 100%;
  background-color: $background-color;
  color: $background-color;
}

body, body * {
  // font-family: $secondary-font-family, $primary-font-family, "Helvetica Neue", sans-serif;
  margin: 0;
}

.backface-visible {
  backface-visibility: 'visible';
  -moz-backface-visibility: 'visible';
  -webkit-backface-visibility: 'visible';
  -ms-backface-visibility: 'visible';
}

.backface-hidden {
  backface-visibility: 'hidden';
  -moz-backface-visibility: 'hidden';
  -webkit-backface-visibility: 'hidden';
  -ms-backface-visibility: 'hidden'
}

.preserve-3d {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/* Same letter spacing than v13 */
button {
  letter-spacing: 0em !important;
}

h1 {
  font-family: $primary-font-family;
  color: $contrast-color;
  font-size: 34px;
  text-align: center;
  margin: 5px;
  line-height: 1.2;
}

h2 {
  font-family: $primary-font-family;
  color: $contrast-color;
  font-size: 25px;
  text-align: center;
  margin: 5px;
  line-height: 1.2;
}

h3 {
  font-family: $secondary-font-family, sans-serif;
  font-weight: 400;
  color: $contrast-color;
  font-size: 20px;
  text-align: center;
  margin: 5px;
}

h4 {
  font-family: $secondary-font-family, sans-serif;
  color: $contrast-color;
  font-size: 16px;
  text-align: center;
  margin: 5px;
}

h5 {
  font-family: $secondary-font-family, sans-serif;
  color: $contrast-color;
  text-align: center;
  margin: 5px;
}

p {
  font-size: 16px;
  margin: 10px;
}

.steps {
  background-color: $background-color;
}

.custom.mat-mdc-card {
  margin: 20px 0px;
}

.custom-spinner circle {
  stroke: $secondary-color;
  display: flex;
}

.star {
  font-size: 3rem;
  padding-inline: 0.2rem;
}

.filled {
  color: #FCC201;
}

/* CONTENT */
mat-sidenav-container {
  height: 100%;
  width: 100%;
}

/* SIDEBAR */
.mat-sidenav {
  padding: 6vw;
  width: 100%;
}

/* CSS CARD */
.visa {
  width: 20%;
  top: 11%;
}

.mastercard {
  width: 18%;
  top: 12%;
}

.redcompra {
  width: 30%;
  top: 15%;
}

.amex {
  width: 25%;
  top: 13%;
}

.diners {
  width: 25%;
  top: 13%;
}

.custom-snackbar {
  background: white;
  color: black;
}

.custom-snackbar button {
  // background-color: $primary-color;
  color:white
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.mat-primary {
  &.mat-mdc-button-base {
    --mat-fab-foreground-color: #fff;
    --mdc-filled-button-label-text-color: #fff;
    --mdc-protected-button-label-text-color: #fff;
  }

  &.mat-mdc-fab,
  &.mat-mdc-mini-fab {
    --mat-icon-color: #fff;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
